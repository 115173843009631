import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const EndUserLicenseAgreement = () => {
  return (
    <Layout>
      <SEO title="End User License Agreement" />
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span
            style={{ fontSize: "18pt", lineHeight: "115%", color: "black" }}
          >
            User Agreement
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span
            style={{ fontSize: "18pt", lineHeight: "115%", color: "black" }}
          >
            &nbsp;
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
          Talsho Technologies LLP
          <span style={{ color: "black" }}> having its office at </span>
          No.47, Siri Apartments Flat no. F3, 6<sup>th</sup> main, 7
          <sup>th</sup>
          cross, near Malleshwaram Police Station, Malleshwaram, Bangalore,
          Karnataka<span style={{ color: "black" }}>, </span>560003, India
          <span style={{ color: "black" }}> bearing </span>LLP Identification
          Number:AAU-6540
          <span style={{ color: "black" }}>
            , owns and operates this content sharing, social networking platform
            under the brand name <b>‘Talsho’</b>, (the “<b>Service(s)</b>” or “
            <b>we</b>” or “<b>us</b>”
          </span>
          <span style={{ color: "black" }}>
            ). The Services are made available by way of mobile application
            along with associated mobile sites, applications and other variants
            (“<b>Platform</b>”).
          </span>
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules there under as applicable and various
          other statues.
        </span>
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#050505" }}
        >
          This user agreement (“<b>User Agreement</b>”) shall be read in
          conjunction and together with the Privacy Policy, in relation to Your
          access to and use of the Services
        </span>
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#666666" }}
        >
          .
        </span>
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          This User Agreement is generated by a computer system and does not
          require any physical or digital signatures.
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          This is a legal and binding agreement between you, the user ("
          <b>User</b>" or "<b>You</b>" or “<b>Your</b>”) of the Services and ‘
        </span>
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
          Talsho Technologies LLP’
          <span style={{ color: "black" }}>
            stating the terms that govern Your use of the mobile application
            along with associated mobile sites, applications and other variants
            (“<b>Platform</b>”). BY ACCEPTING THESE TERMS OF USE IN ANY MANNER
            OR ACCESSING THIS APPLICATION, YOU CONSENT, AGREE AND UNDERTAKE TO
            ABIDE, BE BOUND BY AND ADHERE TO THE TERMS OF USE AND IF YOU DO NOT
            AGREE TO THESE TERMS OF USE, YOU ARE NOT ENTITLED TO ACCESS/ AVAIL
            OF / USE THE SERVICES AND ANY USE THEREAFTER SHALL BE UNAUTHORIZED.
          </span>
        </span>
      </p>
      <p style={{ textAlign: "justify", lineHeight: "115%", border: "none" }}>
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p style={{ textAlign: "justify", lineHeight: "115%", border: "none" }}>
        <b>
          <span
            style={{ fontSize: "15pt", lineHeight: "115%", color: "black" }}
          >
            Terms Of Use
          </span>
        </b>
      </p>
      <ol style={{ marginTop: "0in" }} start={1}>
        <li
          style={{
            color: "black",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Talsho/ the team of Talsho retains the right to deny or suspend
            access to all or a part of the services to anyone at any point of
            time. We also retain the right to create limits on use and storage
            at our sole discretion at any time without prior notice.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0.25in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={2}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            To facilitate your access, use and viewership, the service can
            include advertisements or commercials or may require subscription
            through payment of subscription fee or cookies.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={3}>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span
            style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}
          >
            All Branded Content should be tagged/ uploaded as content for paid
            partnerships through the functionality provided by the Platform and
            with our prior permission upon obtaining our consent, failing which
            we shall have the right to
          </span>
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            take down
            <span style={{ color: "black" }}>
              the Branded Content or remove third party brands, trademarks,
              logos etc. from such Branded Content.
            </span>
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={4}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Talsho/ the team of Talsho reserves the right to access any feature,
            data, images, audio recordings, videos, branding, message, text,
            photographs, graphics, sound, voice, contacts, codes, computer
            programmes, software, database, service information, any form of
            content available through the service, interactive elements/
            features, information of such kind that you utilize and host,
            publish, share, transact, display, upload or engage within the
            course of accessing or using the services, and reserve the right to
            access aforesaid mentioned as and when necessary.
          </span>
        </li>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Talsho/ the team of Talsho holds no responsibility and / or
            liability in respect of any User Content on the Services, including
            without limitations, for intellectual property rights infringement,
            defamation, illegal content or any other violation under applicable
            law. All User Content, which is hosted and transmitted on the
            Services, is the sole responsibility of the person who is
            providing/originating/publishing/posting it. Talsho/ the team of
            Talsho merely carries out the limited function of providing a
            platform for communication and transmission of the User Content.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={6}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            On account of the nature of the Internet, the Services may be
            accessed in various geographical locations; and you hereby agree and
            acknowledge that you are accessing the Services, at your own risk,
            choice and initiative and you agree and undertake to ensure that
            your use of the Services complies with all applicable laws,
            particularly the laws in India and including the local laws in your
            jurisdiction, failing which we reserve the right to take appropriate
            action against you and you are solely responsible to bear the
            liability.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={7}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You may be held liable for losses incurred to Talsho/ the team of
            Talsho or any other user of or visitor of the Platform due to
            authorized or unauthorized use of Your Account, accordingly reserves
            the right to legally proceed against you.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You agree that in connection with the use of the Service and
            Platform, You will not:
          </span>
        </li>
        <ol style={{ marginTop: "0in" }} start={1}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              Take any action (even if requested by another person or user )
              that is, or submit, host, post, comment, display, upload, modify,
              publish, transmit, update, share, or communicate anything that is,
              or that incites or encourages, action that is, explicitly or
              implicitly: illegal, grossly harmful, abusive, harassing,
              threatening, hateful, racially or ethnically objectionable,
              derogatory, harmful to any reputation, disparaging, defamatory,
              libelous, pornographic, indecent, profane, obscene, paedophilic or
              otherwise objectionable (including nudity), blasphemous, invasive
              of another's privacy, or relating or encouraging money laundering
              or gambling or otherwise unlawful in any manner;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={2}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              stalk, harass, threaten, or harm any third party;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={3}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              impersonate any third party;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={4}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              participate in any fraudulent or illegal activity, including
              phishing, money laundering, or fraud;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={5}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              send communication that threatens the unity, integrity, defense,
              security or sovereignty of India, friendly relations with foreign
              states, or public order or causes incitement to the commission of
              any cognizable offence or prevents investigation of any offence or
              is insulting any other nation;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={6}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              violate any law, regulation or court order including the local
              laws of Your jurisdiction;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              infringe any patent, trademark, design, copyright or other
              proprietary rights or violate any right of any third party,
              including privacy rights, publicity rights, and intellectual
              property rights;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={8}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              advocate, encourage, or assist any third party in doing any of the
              foregoing.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={9}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              belongs to another person and to which You do not have any right
              to;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={10}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              harms minors in any way;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={11}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              violates any law for the time being in force;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={12}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              deceives or misleads the addressee about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing in nature;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={13}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              impersonates another person;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={8}>
        <ol style={{ marginTop: "0in" }} start={14}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer resource;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;&nbsp; Otherwise, You are personally liable and legally bound to
          face any consequences arising out of such actions and Talsho/ the team
          of Talsho is not responsible or legally liable for the same.
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={9}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You may be exposed to User Content that might be offensive, harmful,
            inaccurate or otherwise inappropriate. The views expressed by users
            on the Service are not controlled and do not represent the views or
            values of Talsho/ the team of Talsho. We do not hold any
            responsibility or legal liability for such.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={10}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            We do not endorse, support, represent or guarantee the completeness,
            truthfulness, accuracy, or reliability of any User Content or
            communications posted via the Services or endorse any opinions
            expressed via the Services. You understand that by using the
            Services, You may be exposed to User Content that might be
            offensive, harmful, inaccurate or otherwise inappropriate, or in
            some cases, postings that have been mislabeled or are otherwise
            deceptive. Under no circumstances will Talsho/ the team of Talsho be
            liable in any way for any User Content, including, but not limited
            to, any errors or omissions in any User Content, or any loss or
            damage of any kind incurred as a result of the use of any User
            Content posted, emailed, transmitted or otherwise made available via
            the Services or broadcast elsewhere.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={11}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You are personally liable and legally bound against any risk/
            accidents/ unforeseen happenings while using or while in process of
            using the services and Talsho/ the team of Talsho is not responsible
            or legally liable for the same.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={12}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Your interactions with third parties, including users, on or through
            the Service, is solely between you and the third party; however,
            Talsho/ the team of Talsho may, in its sole discretion, intercede
            and You will reasonably cooperate with Talsho/ the team of Talsho,
            if it does so. You acknowledge and agree that Talsho/ the team of
            Talsho will not be responsible for any damages, losses, costs,
            expenses, or liabilities incurred as a result of such interactions,
            and you will not make Talsho/ the team of Talsho a party to any
            dispute between you and any third party with whom you interact with
            on the Service. You hereby release Talsho/ the team of Talsho from
            claims, demands, and damages (actual and consequential) of every
            kind or nature, known and unknown, suspected and unsuspected,
            disclosed and undisclosed, arising out of or in any way related to
            such interactions or the Service.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={13}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Any use or reliance on any User Content or materials posted via the
            Services or obtained by You through the Services is at Your own risk
            and we are not responsible.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={14}>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span
            style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}
          >
            We retain the rights to any User Content You submit, post or display
            on or through the Services. By submitting, posting or displaying
            User Content on or through the Services, You grant Talsho/ the team
            of Talsho a worldwide, non-exclusive, royalty-free, fully paid,
            unlimited, universal, perpetual, and irrevocable exclusive rights,
            in any and all manner and media or distribution methods (now known
            or later developed) to use, copy, reproduce, process, adapt, modify,
            publish, transmit, publicly perform, publicly display and distribute
            such User Content to all users to stream and download, create
            derivative works of and to use the User Content and to advertise,
            market, and promote the Platform and you extinguish all your rights
            of the same, including the right to post such user content in any
            other platforms. Other Users might be able to download,
          </span>
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            screen share
            <span style={{ color: "black" }}>
              and share Your User Content within and outside the Service.
              However, such transmission by others is prohibited and such
              persons will be legally liable for such acts. Further, such data
              so uploaded by the user gives us exclusive rights to use, copy,
              reproduce, process, adapt, modify, publish, transmit, publicly
              perform, publicly display and distribute. You further grant us a
              non-exclusive, royalty-free, universal unlimited right to use Your
              user name, image, voice, and likeness to identify You as the
              source of any of Your User Content.
            </span>
            Y<span style={{ color: "black" }}>ou are further not permitte</span>
            d to
            <span style={{ color: "#050505" }}>
              Modify, decrypt, reverse compile or reverse engineer the platform
              and/ or the services, if done otherwise, shall be legally liable.
              You are further barred from using in any other form or means of
              the platform/ services, for every other purpose except personal
              and non-commercial usage.
            </span>
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#050505" }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={15}>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You are barred from
            <span style={{ color: "#050505" }}>
              transmitting, hosting or otherwise commercially exploiting the
              services or the platform. If done otherwise, you shall be legally
              liable and shall also indemnify
            </span>
            Talsho/ the team of Talsho
            <span style={{ color: "#050505" }}>
              for all losses occurred to us.
            </span>
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#050505" }}
        >
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Intellectual Property Rights:
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={1}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              For the purpose this User Agreement, the terms "Intellectual
              Property Rights" shall mean all patents, trademarks, service
              marks, copyrights, database right, trade names, brand names, trade
              secrets, design rights and similar proprietary rights of Talsho/
              the team of Talsho and/or its licensors and/or other respective
              owners, whether registered or unregistered and all renewals and
              extensions.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={2}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              Save and except for the User Content, all rights, title and
              interest in the Intellectual Property Rights in the Service
              including without limitation all its constituents, content,
              features, functions, text, images, audios, audio-visuals, literary
              work, artistic work, musical work, computer programme, dramatic
              work, sound recording, cinematograph film, artistic work, literary
              work, musical work, a video recording, content performance and
              broadcast as defined under the applicable laws, specifications,
              instructions, abstracts, summaries, audio commentaries/content,
              copy sketches, drawings, artwork, software, source code, object
              code, comments on the source code and object code, domain names,
              application names, designs, database, tools, icons, layout,
              programs, titles, names, manuals, graphics, animation,
              applications, user interface instructions, photographs, artist
              profiles, illustrations, jokes, memes, contests, gaming
              elements/content, interactive elements and all other elements,
              data, information and any other content/materials are the property
              of Talsho/ the team of Talsho and/or its licensors and/or other
              respective owners and are protected, without limitation, pursuant
              to relevant Intellectual Property Rights laws of India and the
              world. Talsho/ the team of Talsho retains full, complete and
              absolute title to the Service and all Intellectual Property Rights
              therein.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={3}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              The Service including any content including User Content thereon
              shall be deemed to be non-exclusively licensed to You by Us only
              for Your non-commercial personal use and only for such period as
              We may, in Our sole discretion, deem appropriate. You shall not
              use, reproduce, redistribute, sell, offer on commercial rental,
              decompile, reverse engineer, disassemble, adapt, communicate to
              the public, make a derivative work, interfere with the integrity
              of the Service (including without limitation the software, coding,
              constituents, elements, content, etc.) in any manner whatsoever or
              if otherwise, will be legally liable for the same.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={4}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              You expressly confirm not to, directly or indirectly, copy,
              reproduce, modify, edit, re-edit, amend, alter, vary, enhance,
              improve, upgrade, create derivative works, translate, adapt,
              abridge, delete, display, perform, publish, distribute,
              communicate to the public, disseminate, broadcast, transmit, sell,
              rent, lease, lend, assign, license, sub-license, disassemble,
              decompile, reverse engineer, market, promote, circulate, exploit,
              digitally alter or manipulate the Service and/or any parts thereof
              (including any and all Materials therein) (in whole or in part) in
              any manner, medium or mode now known or hereinafter developed or
              if otherwise, will be legally liable for the same.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={5}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              You acknowledge and agree that any feedback, comments, or
              suggestions You may provide regarding the Platform or the Services
              are entirely voluntary, and Talsho/ the team of Talsho will be
              free to use such feedback, comments or suggestions as it sees fit
              and without any obligation to You.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={6}>
          <li
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              You are further not permitted to
              <span style={{ color: "#050505" }}>
                Modify, decrypt, reverse compile or reverse engineer the
                platform and/ or the services, if done otherwise, shall be
                legally liable.
              </span>
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={16}>
        <ol style={{ marginTop: "0in" }} start={7}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              We will legally proceed against copyright infringement.&nbsp;
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={17}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            We also reserve the right to access, read, preserve, and disclose
            any information as We reasonably believe is necessary.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={18}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Any and all contests, promotions, surveys and campaigns hosted or
            conducted on the Service are subject to separate contest terms and
            conditions ("<b>Contest and/or Promotion T&amp;Cs</b>") and You are
            requested to read the Contest and/or Promotion T&amp;Cs as well as
            User Agreement before participating in the same and upon
            participation it shall be deemed that the participant has read and
            understood the Contest and/or Promotion T&amp;Cs.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={19}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You agree to fully indemnify, defend and hold harmless Talsho, its
            affiliates, associates and group companies, and their respective
            directors, key managerial personnel, employees, officers,
            shareholders, agents, representatives, sub-contractors, consultants
            and third-party providers from and against all losses, claims and
            damages including legal fees, resulting from: (i) Your violation of
            any terms of the User Agreement; (iii) Your violation of any third
            party right, including without limitation any publicity, privacy, or
            intellectual property right; (iv) Your breach of any applicable
            laws; (iv) any unauthorized, improper, illegal or wrongful use of
            Your account by any person, including a third party, whether or not
            authorized or permitted by You; and (v) Your breach of any
            representation, warranty, covenant or undertaking under the User
            Agreement or under applicable law. This indemnification obligation
            will survive the expiry or termination of this User Agreement and
            Your use of the Service.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={20}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Talsho/ the team of Talsho respect the intellectual property rights
            of others and expects Users of the Services to do the same. We will
            respond to notices of alleged copyright infringement that comply
            with applicable law and are properly provided to Us. These notices
            should only be submitted by the owner of the intellectual property
            or an agent authorized to act on his/her behalf. However, any false
            claim by a user may result in the termination of the Account of such
            user.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={21}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            If You believe that Your User Content or any other User Content has
            been copied in a way that constitutes copyright infringement, please
            provide Us with the following information:
          </span>
        </li>
        <ol style={{ marginTop: "0in" }} start={1}>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              a physical or electronic signature of the copyright owner or a
              person authorized to act on their behalf;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              identification of the copyrighted work claimed to have been
              infringed;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit Us to locate the material;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              Your contact information, including Your address, telephone
              number, and an email address;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              a statement by You that You have a good faith belief that use of
              the material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law;
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              undertaking that You shall file an infringement suit in the
              competent court against the person responsible for uploading the
              infringing copy and produce the orders of the competent court
              having jurisdiction, within a period of twenty-one days from the
              receipt of the take down notice by Talsho/ the team of Talsho; and
            </span>
          </li>
          <li
            style={{
              color: "black",
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "115%",
              border: "none",
            }}
          >
            <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
              a statement that the information in the notification is accurate,
              and, under penalty of perjury, that You are authorized to act on
              behalf of the copyright owner.
            </span>
          </li>
        </ol>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={22}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Others are barred from copying any idea or format of the platform.
            Otherwise, will be legally liable and responsible.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={23}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            The failure of Talsho/ the team of Talsho to enforce any right or
            provision of these terms of User Agreement will not be deemed a
            waiver of such right or provision. In the event that any provision
            of these terms of User Agreement is held to be invalid or
            unenforceable, then that provision will be limited or eliminated to
            the minimum extent necessary, and the remaining provisions of these
            terms of User Agreement will remain in full force and effect.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={24}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Talsho/ the team of Talsho reserves the right to change, modify,
            alter or add any additional term, at anytime without prior notice.
            Such changes / modifications shall come into effect immediately upon
            being posted/ published. Users continued usage of the app shall be
            deemed to be an acceptance of the changed/ modified term. If You do
            not wish to be bound by any such revisions, You must stop accessing
            and using the Services. By continuing to access or use the Services
            after those revisions (from time to time) become effective, You
            agree to be bound by such revised terms.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={25}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            This User Agreement and any action related thereto will be governed
            by the laws of India without regard to or application of its
            conflict of law provisions or Your state or country of residence.
            All claims, disputes, legal proceedings or litigation arising in
            connection with the Services will be brought solely in the competent
            courts of jurisdiction located in Kurnool, Andhra Pradesh, India and
            its respective superior courts and You consent to the jurisdiction
            of and venue in such courts and waive any objection as to
            inconvenient forum.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={26}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You agree that Talsho/ the team of Talsho shall have the right but
            have no obligation, to monitor User Material and to restrict or
            remove User Material that Talsho/ the team of Talsho may determine,
            in its sole discretion, is inappropriate or for any other reason.
            You acknowledge that Talsho/ the team of Talsho reserves the right
            to investigate and take appropriate legal action against anyone who,
            in Talsho/ the team of Talsho’s sole discretion, violates these
            Terms, including, but not limited to, terminating their account,
            terminate, suspend, limit, or use of the Services or access to
            Content and/or reporting such User Material, conduct, or activity,
            to law enforcement authorities, in addition to any other available
            remedies under law or equity.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={27}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You acknowledge that the User Material is not confidential and that
            You have no expectation of privacy in it.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}>
          &nbsp;
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={28}>
        <li
          style={{
            color: "black",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
            border: "none",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            In no event does Talsho/ the team of Talsho assume any
            responsibility or liability whatsoever for any User Material, and
            You agree to waive any legal or equitable rights or remedies You may
            have against Talsho/ the team of Talsho with respect to such User
            Material.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span style={{ fontSize: "15pt", lineHeight: "115%" }}>
            Privacy Policy
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span style={{ fontSize: "15pt", lineHeight: "115%" }}>&nbsp;</span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
          Talsho/ the team of Talsho reserves the right to access any feature,
          data, images, audio recordings, videos, branding, message, text,
          photographs, graphics, sound, voice, contacts, codes, computer
          programmes, software, database, service information, any form of
          content available through the service, interactive elements/ features,
          information of such kind that you utilize and host, publish, share,
          transact, display, upload or engage within the course of accessing or
          using the services, and reserve the right to access aforesaid
          mentioned as and when necessary to facilitate your access, use and
          viewership.
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <b>
          <span style={{ fontSize: "15pt", lineHeight: "115%" }}>
            End User License Agreement
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <b>
          <span style={{ fontSize: "14pt", lineHeight: "115%" }}>&nbsp;</span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "12pt",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#1d1d1f" }}
        >
          Talsho made available through the App Store is licensed, not sold, to
          you. Your license to the App is subject to your prior acceptance of
          either this Licensed Application End User License Agreement (“Standard
          EULA”), or a custom end user license agreement between you and the
          Application Provider (“Custom EULA”), if one is provided. Your license
          to Talsho under this Standard EULA or Custom EULA is granted by the
          App store, and your license to any Third Party App under this Standard
          EULA or Custom EULA is granted by the Application Provider of that
          Third Party App. Any App that is subject to this Standard EULA is
          referred to herein as the “Licensed Application.” The Talsho
          (“Licensor”) reserves all rights in and to the Licensed Application
          not expressly granted to you under this Standard EULA.
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1}>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Scope of License: Licensor grants to you a non transferable license
            to use the Licensed Application on any product that you own or
            control and as permitted by the Usage Rules. The terms of this
            Standard EULA will govern any content, materials, or services
            accessible from or purchased within the Licensed Application as well
            as upgrades provided by Licensor that replace or supplement the
            original Licensed Application, unless such upgrade is accompanied by
            a Custom EULA. Except as provided in the Usage Rules, you may not
            distribute or make the Licensed Application available over a network
            where it could be used by multiple devices at the same time. You may
            not transfer, redistribute or sublicense the Licensed Application
            and, if you sell your Device to a third party, you must remove the
            Licensed Application from the Device before doing so. You may not
            copy (except as permitted by this license and the Usage Rules),
            reverse-engineer, disassemble, attempt to derive the source code of,
            modify, or create derivative works of the Licensed Application, any
            updates, or any part thereof (except as and only to the extent that
            any foregoing restriction is prohibited by applicable law or to the
            extent as may be permitted by the licensing terms governing use of
            any open-sourced components included with the Licensed Application).
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Consent to Use of Data: You agree that Licensor may collect and use
            technical data and related information—including but not limited to
            technical information about your device, system and application
            software, and peripherals—that is gathered periodically to
            facilitate the provision of software updates, product support, and
            other services to you (if any) related to the Licensed Application.
            Licensor may use this information to improve its products or to
            provide services or technologies to you.
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Termination. This Standard EULA is effective until terminated by you
            or Licensor. Your rights under this Standard EULA will terminate
            automatically if you fail to comply with any of its terms.
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            External Services. The Licensed Application may enable access to
            Licensor’s and/or third-party services and websites (collectively
            and individually, "External Services"). You agree to use the
            External Services at your sole risk. Licensor is not responsible for
            examining or evaluating the content or accuracy of any third-party
            External Services, and shall not be liable for any such third-party
            External Services. Data displayed by any Licensed Application or
            External Service, including but not limited to financial, medical
            and location information, is for general informational purposes only
            and is not guaranteed by Licensor or its agents. You will not use
            the External Services in any manner that is inconsistent with the
            terms of this Standard EULA or that infringes the intellectual
            property rights of Licensor or any third party. You agree not to use
            the External Services to harass, abuse, stalk, threaten or defame
            any person or entity, and that Licensor is not responsible for any
            such use. External Services may not be available in all languages or
            in your Home Country, and may not be appropriate or available for
            use in any particular location. To the extent you choose to use such
            External Services, you are solely responsible for compliance with
            any applicable laws and that Licensor is not responsible for any
            such use. Licensor reserves the right to change, suspend, remove,
            disable or impose access restrictions or limits on any External
            Services at any time without notice or liability to you.
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE
            LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY
            SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE
            PROVIDED "AS IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT
            WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES
            AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY
            SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF
            MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A
            PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NON
            INFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION
            OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL
            CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE
            DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
            REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
            OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS
            OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY
            TO YOU.
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO
            EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY
            INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER,
            INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF
            DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR
            LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE
            THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY
            OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
            DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT
            APPLY TO YOU. In no event shall Licensor’s total liability to you
            for all damages (other than as may be required by applicable law in
            cases involving personal injury) exceed the amount of Rupees Five
            Hundred (Rs. 500/-). The foregoing limitations will apply even if
            the above stated remedy fails of its essential purpose.
          </span>
        </li>
        <li
          style={{
            color: "#1d1d1f",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span style={{ fontSize: "12pt", lineHeight: "115%" }}>
            You may not use or otherwise export or re-export the Licensed
            Application except as authorized by Indian Laws. In particular, but
            without limitation, the Licensed Application may not be exported or
            re-exported as against Indian Laws, you represent and warrant that
            you don’t do the otherwise. You also agree that you will not use
            these products for any purposes prohibited by Indian law, including,
            without limitation, the development, design, manufacture, or
            production of nuclear, missile, or chemical or biological weapons.
          </span>
        </li>
        <li
          style={{
            marginBottom: "12pt",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12pt", lineHeight: "115%", color: "black" }}
          >
            This User Agreement and any action related thereto will be governed
            by the laws of India without regard to or application of its
            conflict of law provisions or Your state or country of residence.
            All claims, disputes, legal proceedings or litigation arising in
            connection with the Services will be brought solely in the competent
            courts of jurisdiction located in Kurnool, Andhra Pradesh, India and
            its respective superior courts and You consent to the jurisdiction
            of and venue in such courts and waive any objection as to
            inconvenient forum.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginBottom: "12pt",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span
          style={{ fontSize: "12pt", lineHeight: "115%", color: "#1d1d1f" }}
        >
          Specifically excluded from application to this Agreement is that law
          known as the United Nations Convention on the International Sale of
          Goods.
        </span>
      </p>
      <p
        style={{
          marginTop: "12pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
    </Layout>
  )
}

export default EndUserLicenseAgreement
